<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.rtableTitle1") }}
              </div>

              <div>
                <el-date-picker
                  style="width: auto"
                  v-model="times"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.rtableTitle2") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.packId"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, i) in recommends"
                    :key="i"
                    :value="item.id"
                    :label="
                      utils.formatLang(lang, item.packCnName, item.packEnName)
                    "
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="onExport()">
                  {{ $t("edpBackendCommon.export") }}</el-button
                >
                <el-button type="primary" size="small" @click="editShow = true">
                  {{ $t("edpBackendEntry.rtableTitle8") }}</el-button
                >
              </div>
            </div>
            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                prop="createTime"
                :label="$t('edpBackendEntry.rtableTitle1')"
              >
              </el-table-column>

              <el-table-column
                prop="cname"
                :label="$t('edpBackendEntry.rtableTitle2')"
              >
                <template slot-scope="scope">
                  <span>{{
                    utils.formatLang(
                      lang,
                      scope.row.packCnName,
                      scope.row.packEnName
                    )
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column :label="$t('edpBackendEntry.rtableTitle3')">
                <template slot-scope="scope">
                  <span>{{
                    utils.formatLang(lang, scope.row.cname, scope.row.ename)
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="cdsid"
                :label="$t('edpBackendEntry.rtableTitle4')"
              ></el-table-column>

              <el-table-column prop="dept" :label="$t('ability.infoTitle4')">
              </el-table-column>
              <el-table-column
                prop="subdivision"
                :label="$t('ability.infoTitle5')"
              ></el-table-column>
              <el-table-column :label="$t('ability.infoTitle6')">
                <template slot-scope="scope">
                  <span>{{
                    utils.formatLang(
                      lang,
                      scope.row.directLeaderChineseName,
                      scope.row.directLeaderEnName
                    )
                  }}</span>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="query.current"
              :page-size="query.size"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      width="50%"
      :title="$t('edpBackendEntry.rtableTitle8')"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-table :data="recommends">
        <el-table-column :label="$t('edpBackendEntry.rtableTitle2')">
          <template slot-scope="scope">
            <span>{{
              utils.formatLang(lang, scope.row.packCnName, scope.row.packEnName)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('edpBackendEntry.formTit3')">
          <template slot-scope="scope">
            <el-switch
              @change="setStatus($event, scope.row)"
              style="display: block; margin-bottom: 4px"
              v-model="scope.row.isScheduleCondition"
              active-color="rgba(97, 191, 180, 1)"
              inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "backendUser",
  data() {
    return {
      pageList: [{}],
      pageTotal: 0,
      loading: false,
      editShow: false,
      times: ["", ""],
      query: {
        current: 1,
        size: 10,
        packId: "",
        createTimeStart: "",
        createTimeEnd: "",
      },
      recommends: [],
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  watch: {
    times(val) {
      console.log(val);
      if (val) {
        this.query.createTimeStart = val[0];
        this.query.createTimeEnd = val[1];
      }
    },
  },
  mounted() {
    this.getList();
    this.getRecommend();
  },
  methods: {
    ...mapActions({
      crowdList: "user/crowdList",
      setCrowd: "user/setCrowd",
      recommendList: "user/recommendList",
      crowdExport: "user/crowdExport",
    }),
    async getList(val) {
      if (val) {
        this.query.current = val;
      }
      let params = { ...this.query };
      let res = await this.crowdList(params);
      console.log(res);
      this.pageList = res.data.records;
      this.query.current = res.data.current;
      this.query.size = res.data.size;
      this.pageTotal = res.data.total;
    },
    async getRecommend() {
      let res = await this.recommendList();
      this.recommends = res.data;
    },
    handleCurrentChange(val) {
      this.query.current = val;
      this.getList();
    },
    onclear() {
      this.query = {
        current: 1,
        size: 10,
        packId: "",
        createTimeStart: "",
        createTimeEnd: "",
      };
      this.times = ["", ""];
    },
    onClose(done) {
      this.getList(1);
      this.getRecommend();
      done();
    },
    onExport() {
      if (!this.query.packId) {
        this.$message({
          type: "warning:",
          message: `${this.$t("edpBackendCommon.select")}${this.$t(
            "edpBackendEntry.rtableTitle2"
          )}`,
        });
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        packId: this.query.packId,
        createTimeStart: this.query.createTimeStart,
        createTimeEnd: this.query.createTimeEnd,
      };
      this.crowdExport(params).then((res) => {
        console.log(res)
        const name = "推荐人群包导出表.xlsx";
        const blob = new Blob([res]);
        const url = window.URL.createObjectURL(blob);
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", name);
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink); // 下载完成移除元素
        window.URL.revokeObjectURL(url); // 释放掉blob对象
        loading.close();
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });
      });
    },
    async setStatus(val, row) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await this.setCrowd(row.id);
      console.log(res);
      if (res.success) {
        loading.close();
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });
      } else {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
